import { render, staticRenderFns } from "./JobDetailsBPH.vue?vue&type=template&id=45f71945&scoped=true&"
import script from "./JobDetailsBPH.vue?vue&type=script&lang=js&"
export * from "./JobDetailsBPH.vue?vue&type=script&lang=js&"
import style0 from "./JobDetailsBPH.vue?vue&type=style&index=0&id=45f71945&prod&scoped=true&lang=css&"
import style1 from "./JobDetailsBPH.vue?vue&type=style&index=1&id=45f71945&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f71945",
  null
  
)

export default component.exports