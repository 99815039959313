<template>
    <div class="pa-5">
        <v-row>
            <v-col cols="12" md="9">
                <v-alert color="grey" class="lighten-2" v-if="job.job_status == 'allocated'">
                    This Job has been allocated and waiting to be Accepted.
                </v-alert>
                <v-alert color="primary" class="darken-2 white--text" v-if="['allocated', 'completed'].indexOf(job.job_status) == -1">
                    This Job is being processed
                </v-alert>
                <v-alert color="success" class="darken-2 white--text" v-if="job.job_status == 'completed'">
                    This Job has been completed at {{ job.completed_at }}
                </v-alert>
                <v-card>
                    <v-card-text class="pa-2">
                        <v-row>
                            <v-col>
                                <v-btn x-small text @click="redirect('Jobs')"><v-icon>mdi-arrow-left</v-icon></v-btn>&nbsp;
                                <strong>CUSTOMER DETAILS</strong>
                            </v-col>
                            <v-col class="text-right" v-if="job.job_status != 'completed'">
                                <v-btn small
                                    dark
                                    v-if="!edit_mode"
                                    @click="edit()">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                                
                                
                                <v-btn color="indigo" small
                                    v-if="edit_mode"
                                    class="darken-4 white--text mr-2" 
                                    @click="update()">
                                    <v-icon>mdi-floppy</v-icon>
                                </v-btn>

                                <v-btn color="red" small
                                    v-if="edit_mode"
                                    class="darken-4 white--text" 
                                    @click="cancelEdit()">
                                    <v-icon>mdi-undo</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field label="Client Name" readonly outlined dense v-model="job.client_name" :readonly="!edit_mode"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Priority" outlined dense v-model="job.priority" v-if="!edit_mode"></v-text-field>
                                <v-select dense 
                                    hide-details 
                                    label="Priority" 
                                    outlined
                                    :items="bph_priorities"
                                    v-if="edit_mode"
                                    v-model="job.priority">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Client Email" readonly outlined dense v-model="job.client.email" :readonly="!edit_mode"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Contact Number" readonly outlined dense v-model="job.client_ref_no" :readonly="!edit_mode"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mt-5">
                    <v-card-text class="pa-2">
                        <strong>JOB DETAILS</strong>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8" sm="12">
                                <v-select
                                    v-if="edit_mode"
                                    :items="job_types"
                                    item-text="job_type"
                                    item-value="id"
                                    label="Job Type Request"
                                    outlined
                                    dense
                                    v-model="job.job_type_id"
                                    >
                                </v-select>
                                <v-text-field label="Job Type Request" v-model="job.job_type" outlined dense 
                                    v-if="!edit_mode" 
                                    readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <v-text-field 
                                    :readonly="!edit_mode"
                                    label="Job Number"
                                    v-model="job.job_number" outlined dense>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="8" sm="12">
                                <v-text-field label="Job Address" v-model="job.job_address" outlined dense 
                                    :readonly="!edit_mode">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <v-text-field 
                                    v-if="!edit_mode"
                                    label="NCC Compliance"
                                    v-model="job.version" outlined dense
                                    readonly>
                                </v-text-field>
                                <v-select dense 
                                    v-if="edit_mode"
                                    hide-details 
                                    label="NCC Compliance" 
                                    outlined
                                    :items="['2019', '2022 - WOH']"
                                    v-model="job.version">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-textarea label="Notes" dense outlined v-model="job.notes" :readonly="!edit_mode"></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card class="mt-5">
                    <v-card-text color="grey" class="pa-2">
                        <v-row>
                            <v-col>
                                <strong>PLANS</strong>
                            </v-col>
                            <v-col class="text-right" v-if="job.job_status != 'completed'">
                                <div v-if="!new_plan">
                                    <v-btn small color="grey darken-3 mr-3 white--text" @click="addPlan('file')">
                                        <v-icon>mdi-file-plus</v-icon> file
                                    </v-btn>
                                    <v-btn small color="orange darken-2 white--text" @click="addPlan('link')"> 
                                        <v-icon>mdi-link</v-icon> URL
                                    </v-btn>
                                </div>
                                <div v-if="new_plan">
                                    <v-btn small color="primary darken-3 mr-3 white--text" @click="savePlan()">
                                        <v-icon>mdi-floppy</v-icon> save
                                    </v-btn>
                                    <v-btn small color="red darken-2 white--text" @click="new_plan = null"> 
                                        <v-icon>mdi-undo</v-icon> Cancel
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="new_plan">
                        <v-file-input
                            prepend-icon="mdi-file-plus"
                            dense
                            hide-details
                            outlined
                            show-size
                            label="Plan File"
                            v-model="new_plan.file"
                            v-if="new_plan.type == 'file'"
                        ></v-file-input>
                        <v-text-field
                            dense
                            hide-details
                            outlined
                            prepend-icon="mdi-link"
                            label="Plan URL"
                            v-model="new_plan.file"
                            v-if="new_plan.type == 'link'"
                        ></v-text-field>
                    </v-card-text>
                    <v-divider v-if="new_plan"></v-divider>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead v-if="job.job_status != 'completed'">
                                <tr>
                                    <th class="text-left">
                                        File
                                    </th>
                                    <th class="text-left" style="width: 50px;">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="p, idx in job.plans"
                                :key="p.id"
                                >
                                    <td class="text-blue pointer" @click="openLink('plans', p.job_id, p.type, p.filename)">
                                        {{ p.filename }}
                                    </td>
                                    <td class="text-center">
                                        <v-btn icon small color="error" 
                                            @click="deletePlanFile(idx, p.id)"
                                            v-if="job.job_status != 'completed'">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                <v-card class="mt-5">
                    <v-card-text color="grey" class="pa-2">
                        <v-row>
                            <v-col>
                                <strong>Zip Files</strong>
                            </v-col>
                            <v-col class="text-right" v-if="job.job_status != 'completed'">
                            <div v-if="!new_zip">
                                    <v-btn small color="grey darken-3 mr-3 white--text" @click="addZip('file')">
                                        <v-icon>mdi-file-plus</v-icon> file
                                    </v-btn>
                                    <v-btn small color="orange darken-2 white--text" @click="addZip('link')"> 
                                        <v-icon>mdi-link</v-icon> URL
                                    </v-btn>
                                </div>
                                <div v-if="new_zip">
                                    <v-btn small color="primary mr-2 white--text" @click="saveZip()">
                                        <v-icon>mdi-floppy</v-icon> Save
                                    </v-btn>
                                    <v-btn small color="red darken-2 white--text" @click="new_zip = null;"> 
                                        <v-icon>mdi-undo</v-icon> Cancel
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="new_zip">
                        <v-file-input
                            prepend-icon="mdi-file-plus"
                            dense
                            hide-details
                            outlined
                            show-size
                            label="Zip File"
                            v-model="new_zip.file"
                            v-if="new_zip.type == 'file'"
                        ></v-file-input>
                        <v-text-field
                            dense
                            hide-details
                            outlined
                            prepend-icon="mdi-link"
                            label="Document URL"
                            v-model="new_zip.file"
                            v-if="new_zip.type == 'link'"
                        ></v-text-field>
                    </v-card-text>
                    <v-divider v-if="new_zip"></v-divider>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead v-if="job.job_status != 'completed'">
                                <tr>
                                    <th class="text-left">
                                        File
                                    </th>
                                    <th class="text-left" style="width: 50px;">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="d, idx in job.zips"
                                    :key="d.id"
                                >
                                    <td class="text-blue pointer" @click="openLink('zips', d.job_id, d.type, d.filename)">
                                        {{ d.filename }}
                                    </td>
                                    <td class="text-center"
                                        v-if="job.job_status != 'completed'">
                                        <v-btn icon small color="error" @click="deleteZipFile(idx, d.id)">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                <v-card class="mt-5">
                    <v-card-text color="grey" class="pa-2">
                        <v-row>
                            <v-col>
                                <strong>Trade Plan Files</strong>
                            </v-col>
                            <v-col class="text-right" v-if="job.job_status != 'completed'">
                            <div v-if="!new_trade_plan">
                                    <v-btn small color="grey darken-3 mr-3 white--text" @click="addTradePlan('file')">
                                        <v-icon>mdi-file-plus</v-icon> file
                                    </v-btn>
                                    <v-btn small color="orange darken-2 white--text" @click="addTradePlan('link')"> 
                                        <v-icon>mdi-link</v-icon> URL
                                    </v-btn>
                                </div>
                                <div v-if="new_trade_plan">
                                    <v-btn small color="primary mr-2 white--text" @click="saveTradePlan()">
                                        <v-icon>mdi-floppy</v-icon> Save
                                    </v-btn>
                                    <v-btn small color="red darken-2 white--text" @click="new_trade_plan = null;"> 
                                        <v-icon>mdi-undo</v-icon> Cancel
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="new_trade_plan">
                        <v-file-input
                            prepend-icon="mdi-file-plus"
                            dense
                            hide-details
                            outlined
                            show-size
                            label="Zip File"
                            v-model="new_trade_plan.file"
                            v-if="new_trade_plan.type == 'file'"
                        ></v-file-input>
                        <v-text-field
                            dense
                            hide-details
                            outlined
                            prepend-icon="mdi-link"
                            label="Document URL"
                            v-model="new_trade_plan.file"
                            v-if="new_trade_plan.type == 'link'"
                        ></v-text-field>
                    </v-card-text>
                    <v-divider v-if="new_trade_plan"></v-divider>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead v-if="job.job_status != 'completed'">
                                <tr>
                                    <th class="text-left">
                                        File
                                    </th>
                                    <th class="text-left" style="width: 50px;">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="d, idx in job.trade_plans"
                                    :key="d.id"
                                >
                                    <td class="text-blue pointer" @click="openLink('trade-plans', d.job_id, d.type, d.filename)">
                                        {{ d.filename }}
                                    </td>
                                    <td class="text-center"
                                        v-if="job.job_status != 'completed'">
                                        <v-btn icon small color="error" @click="deleteTradePlan(idx, d.id)">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                <v-card color="indigo" class="mt-5 darken-3 white--text" dark v-if="job.job_status == 'completed'">
                    <v-card-text class="pa-2">
                        <strong>SUBMISSION DETAILS</strong>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    readonly
                                    label="Regions" 
                                    outlined 
                                    dense 
                                    v-model="job.region_sz"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field 
                                    readonly
                                    label="Floor Basis" 
                                    outlined 
                                    dense 
                                    v-model="job.floor_basis"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field 
                                    readonly
                                    label="Base Heating Load (Mj/M2)" 
                                    outlined 
                                    dense 
                                    v-model="job.base_heating_load"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field 
                                    readonly
                                    label="Base Cooling Load (Mj/M2)" 
                                    outlined 
                                    dense 
                                    v-model="job.base_cooling_load"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea 
                                    readonly
                                    hide-details
                                    label="Run Notes"
                                    v-model="job.run_notes"
                                    outlined
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div>
                                    <label for="">Plan Complexity</label>
                                </div>
                                <v-rating
                                    readonly
                                    v-model="job.plan_complexity"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    hover
                                    large
                                    disabled
                                    >
                                </v-rating>
                                <div>
                                    <small>1 star = Hard to model 5 star = Easy to model</small>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row class="mt-3">
                            <v-col cols="12" md="6">
                                <v-card color="indigo" class="darken-4">
                                    <v-card-text class="pa-2">
                                        <strong>FR5</strong>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-list color="indigo" class="darken-4" dense>
                                        <v-list-item v-for="f, idx in job.frates" :key="f.id">
                                            <v-list-item-content>
                                                <v-btn color="orange" class="darken-2" small @click="openLink('frates', f.job_id, f.type, f.filename)">{{ f.filename }}</v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card dark color="indigo" class="darken-4">
                                    <v-card-text class="pa-2">
                                        <strong>DB FILES</strong>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-list color="indigo" class="darken-4" dense>
                                        <v-list-item v-for="f, idx in job.dbs" :key="f.id">
                                            <v-list-item-content>
                                                <v-btn color="orange" class="darken-2" small @click="openLink('dbs', f.job_id, f.type, f.filename)">{{ f.filename }}</v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="3">
                <div class="pb-2"><strong>Comments</strong></div>
                <v-divider></v-divider>
                <div class="comment-section">
                    <v-btn small text block @click="comment_dialog = true;">Write a comment</v-btn>
                </div>
                <v-divider></v-divider>
                <div class="comment-trail" id="comment-trail">
                    <div class="comment-trail-item" v-for="comment in job.comments?.slice(offset, 10 * page)" v-bind:key="comment.id">
                        <div class="comment-avatar-cont">
                            <v-avatar
                            :color="comment.account_type == 'client' ? 'red' : 'primary'"
                            size="40"
                            >
                            <span class="white--text text-h5">{{ comment.name.substring(0,1) }}</span>
                            </v-avatar>
                        </div>
                        <div class="comment-content-cont">
                            <div class="author">{{ comment.name }}</div>
                            <div class="date">{{ comment.created_at }}</div>
                            <div class="content" v-html="comment.message"></div>
                        </div>
                    </div>
                </div>
                <v-divider v-if="job.comments?.length > 10"></v-divider>
                <div class="text-center pa-2" v-if="job.comments?.length > 10">
                    <v-btn small 
                        class="mr-2"
                        :disabled="page == 1"
                        @click="paginate(-1)" 
                        >Prev</v-btn>
                    <v-btn small 
                        :disabled="page >= total_pages"
                        @click="paginate(1)"
                        >Next
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="comment_dialog" width="500">
            <v-card>
                <v-card-title>
                    Write your comment
                </v-card-title>
                <editor :content.sync="comment" @update="updateComment"></editor>
                <v-card-actions>
                    <v-btn small color="primary" block @click="postComment()" :disabled="$store.state.loading">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .font-sm {
        font-size : 14px;
    }
    .text-underline {
        text-decoration: underline;
    } 
    .text-blue {
        color : blue;
    }
    .pointer {
        cursor: pointer;
    }
</style>
<script>
import {globalMixins} from '@/mixins/globalMixins.js';
import axios from 'axios';
import Dialog from '@/components/Dialog.vue';
import Editor from '@/components/Editor.vue';
export default {
    name : 'JobDetails',
    mixins : [globalMixins],
    components : {
        Dialog,
        Editor
    },
    data() {
        return {
            new_plan : null,
            new_trade_plan : null,
            new_zip : null,
            edit_mode : false,
            attachment : {},
            job : {},
            copy : {},
            comment_dialog : false,
            comment : null,
            offset : 0,
            page : 1,
            total_pages : 0
        }
    },
    mounted() {
        axios({
            url : this.$store.state.api_url + '/jobs/' + this.$route.params.id,
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            this.job = res.data;

            this.total_pages = Math.ceil(this.job.comments.length / 10);
        });

        axios({
            url : this.$store.state.api_url + '/categories/' + this.$store.getters.getUser.category_id + '/job-types',
            method : 'GET',
            headers : {
                'Authorization' : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            this.job_types = res.data.data;
        }).catch(err => {

        });
    },
    methods: {
        viewPlanFile(filename, type) {
            if(type == 'file') {
                window.open(this.$store.state.host_url + '/uploads/plans/' + this.job.id + '/' + filename, '_blank');
            }
            else {
                window.open(filename, '_blank');
            }
        },
        addPlan(type) {
            this.new_plan = {
                type : type,
                file : null
            };
        },
        removeNewPlan(idx) {
            this.job.plans.splice(idx, 1);
        },
        savePlan() {
            this.$store.state.loading = true;
            var data = new FormData();
            data.append('file', this.new_plan.file);
            data.append('type', this.new_plan.type);

            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/plans',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.plans.push(res.data.data);
                this.$store.state.loading = false;
                this.new_plan = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            })

        },
        addTradePlan(type) {
            this.new_plan = {
                type : type,
                file : null
            };
        },
        removeNewTradePlan(idx) {
            this.job.trade_plans.splice(idx, 1);
        },
        saveTradePlan() {
            this.$store.state.loading = true;
            var data = new FormData();
            data.append('file', this.new_plan.file);
            data.append('type', this.new_plan.type);

            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/trade-plans',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.plans.push(res.data.data);
                this.$store.state.loading = false;
                this.new_plan = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            })

        },
        
        addZip(type) {
            this.new_zip = {
                file : null,
                type : type
            };
        },
        saveZip() {
            this.$store.state.loading = true;
            var data = new FormData();
            data.append('file', this.new_zip.file);
            data.append('type', this.new_zip.type);

            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/zips',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.zips.push(res.data.data);
                this.$store.state.loading = false;
                this.new_zip = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            })
        },
        removeNewZip(idx) {
            this.job.documents.splice(idx, 1);
        },
        viewDocumentFile(filename, type) {
            if(type == 'file') {
                window.open(this.$store.state.host_url + '/uploads/documents/' + this.job.id + '/' + filename, '_blank');
            }
            else {
                window.open(filename, '_blank');
            }
        },
        viewFrateFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/frates/' + this.job.id + '/' + filename);
        },
        viewDbFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/dbs/' + this.job.id + '/' + filename);
        },
        deleteZipFile(idx, id) {
            if(confirm('Are you sure?')) {
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/zips/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.zips.splice(idx, 1);
                }).catch(err => {
                    alert(JSON.stringify(err));
                });
            }
        },
        deleteTradePlan(idx, id) {
            if(confirm('Are you sure?')) {
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/trade-plans/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.trade_plans.splice(idx, 1);
                }).catch(err => {
                    alert(JSON.stringify(err));
                });
            }
        },
        deletePlanFile(idx, id) {
            if(confirm('Are you sure?')) {
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/plans/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.plans.splice(idx, 1);
                }).catch(err => {
                    alert(JSON.stringify(err));
                });
            }
        },
        deleteDocumentFile(idx, id) {
            if(confirm('Are you sure?')) {
                axios({
                    url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/documents/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.documents.splice(idx, 1);
                }).catch(err => {
                    alert(JSON.stringify(err));
                });
            }
        },
        update() {
            if(confirm('Are you sure?')) {
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/update',
                    method : 'PUT',
                    data : this.job,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job = res.data;
                    this.edit_mode = false;
                }).catch(err => {
                    console.log(err);
                    alert(err.response.data.errors.join("\n"));
                });
            }
        },
        edit() {
            this.copy = {...this.job}
            this.edit_mode = true;
        },
        cancelEdit() {
            this.edit_mode = false;
            this.job = this.copy;
        },
        updateComment(comment) {
            this.comment = comment;
        },
        postComment() {
            this.$store.commit('changeLoading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.$route.params.id + '/conversations',
                method : 'POST',
                data : {message : this.comment, job_id : this.$route.params.id},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.$store.commit('changeLoading', false);
                this.comment_dialog = false;
                this.job.comments.unshift(res.data);
            }).catch(err => {
                this.$store.commit('changeLoading', false);
            })
        },
        paginate(jump) {
            this.page += jump;
            this.offset = (this.page * 10) - 10;
            console.log(this.offset);
        }
    }
}
</script>
<style scoped>
.comment-trail {
    padding : 10px 0;
}
.comment-trail-item {
    display: flex;
}
.comment-trail-item .author {
    font-weight : bold;
}
.comment-trail-item .date {
    font-size: 12px;
}
.comment-trail-item .content {
    font-size: 13px;
}
.comment-content-cont {
    padding : 0px 15px;
}
</style>